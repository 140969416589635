import React from "react";
import Layout from "../../components/Layout";

export default () => (
  <Layout pageId="contact" metadata={{ title: "Thanks for contacting Impactual" }}>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1 className="h3">Thank you for reaching out to Impactual!</h1>
          <p className="body-1">We'll be in touch.</p>
        </div>
      </div>
    </section>
  </Layout>
);
